<template>
	<v-container>
		<v-row>
			<v-col cols="3">
				<v-sheet
					class="mx-auto"
					rounded="lg"
					style="position: sticky; top: 76px"
					min-height="30vh"
				>
					<v-list color="transparent">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									class="
										text-h6
										font-weight-light
										text-uppercase
									"
									>{{ $t("news") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="mt-1"></v-divider>

						<v-list-item-group color="primary">
							<v-list-item
								class="v-item--active v-list-item--active"
							>
								<v-list-item-content>
									<v-list-item-title class="text-button"
										>关于邀请出席“投资墨西哥发展论坛”的函</v-list-item-title
									>
									<v-list-item-subtitle class="text-caption"
										>2022-05-10</v-list-item-subtitle
									>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-sheet>
			</v-col>

			<v-col cols="6">
				<v-sheet min-height="70vh" rounded="lg" class="px-12">
					<post20220510></post20220510>
				</v-sheet>
			</v-col>

			<v-col cols="3">
				<v-sheet
					rounded="lg"
					style="position: sticky; top: 76px"
					min-height="30vh"
				>
					<v-list color="transparent">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									class="
										text-h6
										font-weight-light
										text-uppercase
									"
									>{{ $t("socialMedia") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="mt-1"></v-divider>
						<div class="accent py-4">
							<div class="text-overline text-center white--text">
								Follow us for updates
							</div>
							<div class="d-flex justify-center">
								<div v-for="(social, i) in socials" :key="i">
									<v-img
										:src="
											require(`@/assets/images/features/${social.name}.png`)
										"
										contain
										height="80"
										width="110"
									/>
									<div class="text-center white--text mt-1">
										<v-icon color="white"
											>mdi-{{ social.icon }}</v-icon
										>
										<span class="text-caption ml-1">{{
											$t(social.name)
										}}</span>
									</div>
								</div>
							</div>
						</div>
					</v-list>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Post20220510 from "@/views/news/posts/2022-05-10-post.vue";

export default {
	components: {
		Post20220510,
	},

	data: () => ({
		socials: [
			{
				name: "wechat",
				icon: "wechat",
			},
			{
				name: "weibo",
				icon: "sina-weibo",
			},
			{
				name: "tiktok",
				icon: "music-note-outline",
			},
		],
	}),
};
</script>